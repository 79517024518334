/* 
      show 
  */

.back-btn {
  padding: 7px;
}
.addmore-btn {
  padding: 7px;
  background-color: #abc;
  transition: 0.3s;
  cursor: pointer;
}
.addmore-btn:hover {
  background-color: rgb(233, 150, 122);
}
.back-link,
.addmore-link {
  width: 150px;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addmore-link {
  color: #fff;
}
.back-link span,
.addmore-link span {
  margin-left: 5px;
}

.show {
  /* display: flex; */
  display: grid;
  grid-template-columns: 200px auto;
  grid-template-rows: 100%;
}
.sidebar-container {
  height: 100vh;
  background-color: #ccc;
}
.sidebar {
  position: sticky;
  top: 0;
  padding: 7rem 0;
}
.to-top {
  color: #fff;
  text-shadow: 1px 1px 3px rgb(187, 221, 215);
  position: fixed;
  top: 2rem;
  left: 1.5rem;
}
.categories-wrapper {
  background-color: #ccc;
  width: 200px;
  height: 100%;
  position: fixed;
  top: 13rem;
  left: 0;
}
.show-current-category {
  display: flex;
  justify-content: right;
  background-color: aquamarine;
  padding: 10px;
  width: 120%;
}
.categories-wrapper .categories .cat:hover {
  background-color: rgba(233, 150, 122, 0.6);
}
.cat-options {
  margin: 5rem 0;
}
.cat-options a {
  display: block;
  color: #333;
  cursor: pointer;
}
.blog-wrapper {
  padding: 2rem 0 5rem;
}
.show-img {
  margin: 2rem 0;
  text-align: center;
}
.show-img img {
  width: 70%;
  object-fit: cover;
}
.show-texts {
  margin: 0 2rem;
  text-align: center;
}
.blog-detail {
  width: 80%;
  margin: 8rem auto 0;
  padding: 1rem;
  text-align: left;
}
.show-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-border {
  padding-top: 0.5rem;
  border-bottom: 1px orange solid;
  width: 50%;
}
.show-author-date {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-top: 0.5rem;
}
.show-auth {
  text-align: left;
  padding-left: 1rem;
}
.show-place,
.show-body {
  margin: 2rem 0;
  text-align-last: center;
}

@media (max-width: 890px) {
  .show {
    display: block;
  }
  .sidebar {
    display: none;
  }
  .sidebar-container {
    display: none;
  }
  .bb-posi {
    width: 100%;
    left: 0rem;
    top: 5rem;
    justify-content: center;
  }
  .blog-detail {
    width: 100%;
    margin: 8rem 0 0;
    padding: 0;
  }
}
