/*
      new / edit blog
  */
.page-h1 {
  width: 50%;
  margin: 2rem auto;
  padding-bottom: 1rem;
}

.new,
.edit {
  width: 70%;
  margin: 7rem auto;
}

.category-select {
  display: flex;
}

/*  
      add  edit  del  
  */
.input-container {
  width: 80%;
  margin: 1rem auto;
}
.input-container-btns {
  margin-top: 5rem;
}
.input-container input,
.input-container textarea {
  width: 100%;
}
.label {
  display: block;
}
.imgsize-info {
  font-size: 12px;
}

@media (max-width: 890px) {
  .new,
  .edit {
    width: 100%;
  }
}
