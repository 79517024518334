@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Exo+2:wght@400;500&family=Karla:wght@300&family=Titillium+Web&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Exo 2", sans-serif;
  letter-spacing: 3px;
}
body {
  text-align: center;
  color: #555;
}

/* 
  home,
  register & login

*/
.topage {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home,
.register,
.login {
  width: 100vw;
  height: 100vh;
}
.home {
  background-color: #ccdcee8a;
}
.login {
  background-color: rgba(249, 207, 156, 0.5);
}
.register {
  background-color: rgba(125, 185, 165, 0.5);
}

.home-container,
.resister-container,
.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;

  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  border-radius: 10px;
  padding: 3rem;
}

.home .input-container {
  margin: 3rem auto;
}
.home .input-container a {
  display: block;
  margin: 1rem 0;
}
.welcome {
  font-size: 2rem;
}
.to {
  font-size: 1.3rem;
  padding-top: 1rem;
}
.goToSharelog {
  display: block;
  font-size: 4.5rem;
  transition: 0.3s;
  color: #fff;
  text-shadow: 2px 2px 5px rgb(182, 182, 182);
}
.goToSharelog:hover {
  text-shadow: 2px 2px 5px rgb(231, 196, 193);
 
}

/* 
    general 
*/
input,
textarea {
  width: 100%;
  height: 2rem;
  outline: none;
}
textarea {
  width: 100%;
  height: 25rem;
}
label {
  display: block;
  text-align: left;
  margin-bottom: 0.3rem;
}

/* 
    layout 
*/
.blogs-container {
  width: 80%;
  margin: 7rem auto;
}

.error {
  margin: 1rem 0;
  color: red;
}
