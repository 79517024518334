/* 
    header & footer 
*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem 0;
  width: 100vw;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
}
.header p {
  text-align: left;
  color: #fff;
  text-shadow: 1px 1px 3px rgb(187, 221, 215);
}

.main .header {
  justify-content: right;
  padding: 1rem;
}

.show .header,
.edit .header,
.new .header {
  background: linear-gradient(
    to bottom,
    rgb(201, 223, 219) 0%,
    rgb(201, 223, 219) 50%,
    transparent 100%
  );
}
.auth-link {
  margin: 0 0.5rem;
}

/*  */

.footer p {
  padding: 1rem 0;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.5);
}
.home .footer {
  position: fixed;
  bottom: 0;
}
.login .footer p,
.register .footer p,
.show .footer,
.cat-page-wrapper .footer {
  position: fixed;
  bottom: 0;
  left: 0;
}
