.cat-page-wrapper {
  height: 100vh;
}
.category-page {
  display: grid;
  grid-template-columns: 200px auto;
  grid-template-rows: 100%;
}
.to-top-link {
  position: absolute;
  top: 1.5rem;
  left: 230px;
  color: #333;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
}
.to-top-icon {
  font-size: 20px;
  color: #333;
  display: inline-block;
  margin: 0.5rem;
}
.to-top-link:hover {
  color: #fff;
  text-shadow: 0px 0px 1px #333;
}

.category-page .blogs-wrapper {
  padding: 3rem 0;
}
.blog-cards {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5rem 0;
}
.cat-options h4 {
  padding: 5px 0;
}
.categories .cat {
  transition: 0.3s;
  padding: 7px 0;
}
.categories .cat:hover {
  color: #fff;
  background-color: rgba(95, 158, 160, 0.6);
}

@media (max-width: 890px) {
  .category-page {
    display: block;
  }
}
