/* 
    top index / imgs 
*/

.comment-login {
  width: 70%;
  margin: 0 auto;
  padding: 3rem 0;
  background-color: rgba(250, 250, 210, 0.8);
  /* background-color: #eee; */
}
.comment-login a {
  color: indianred;
}

.main-cat .categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5rem 0;
}
.main-cat .categories .cat {
  color: #fff;
  background-color: cadetblue;
  width: 5rem;
  height: 5rem;
  border-radius: 50% 50%;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.main-cat .categories .cat:hover {
  background-color: rgba(95, 158, 160, 0.6);
}

.siteimg {
  width: 100vw;

  height: 50vh;
  background: linear-gradient(
    90deg,
    rgb(231, 196, 193) 0%,
    rgb(231, 196, 193) 50%,
    rgb(201, 223, 219) 50%,
    rgb(201, 223, 219) 100%
  );
}
.site-title {
  font-family: "Comfortaa", cursive;
  font-size: 3rem;
  letter-spacing: 3px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  padding: 2rem 5rem;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}
.subtitle {
  padding: 5rem;
  /* background-color: #eee; */
}

/*  
        top index / add btn  
    */
.add-btn {
  width: 100%;
  background-color: #fff;
  border: none;
}
.add-btn a {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  max-width: 220px;
  padding: 1rem;
  color: #555;
  transition: 0.3s ease-in-out;
  font-weight: 600;
}
.add-btn a:before,
.add-btn a:after {
  position: absolute;
  width: 100%;
  height: 1px;
  content: "";
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #777;
}
.add-btn a:before {
  top: 0;
  left: 0;
}
.add-btn a:after {
  right: 0;
  bottom: 0;
}
.add-btn a:hover {
  color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: linear-gradient(
    -45deg,
    rgb(231, 196, 193) 0%,
    rgb(231, 196, 193) 50%,
    rgb(201, 223, 219) 50%,
    rgb(201, 223, 219) 100%
  );
}
.add-btn a:hover:before,
.add-btn a:hover:after {
  width: 0;
}

.reg-link,
.login-link {
  color: royalblue;
  transition: 0.3s;
}
.reg-link:hover,
.login-link:hover {
  color: orangered;
}

/*
      top index / blogs 
  */
.nopost {
  margin: 3rem 0;
}
.blogs-wrapper {
  padding: 5rem 0;
  background-color: #eee;
}
.all-blogs {
  font-size: 2rem;
}
.blogs {
  max-width: 80%;
  max-height: auto;
  margin: 2rem auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.blog-container {
  position: relative;
  width: 300px;
  height: 180px;
  padding: 1rem;
  background-color: rgb(164, 212, 196);
  color: #fff;
  transition: 0.3s;
}
.blog-container::after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  height: 85%;
  border: 1px solid #fff;
  transition: 0.3s;
  /* z-index: 1; */
}
.blog-container:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.box-img img {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.blog {
  width: 250px;
  height: 100%;
  margin: 0 auto;
  padding: 1.2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.box-top {
  width: 100%;
}
.blog-title {
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.blog-created-time {
  width: 100%;
  font-size: 0.7rem;
}
.blog-author {
  width: 100%;
  font-size: 1rem;
}
.box-bottom {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0.7rem;
}
.read-btn {
  color: #333;
  margin: 1rem;
  position: relative;
}

@media (max-width: 450px) {
  .site-title {
    width: 100%;
  }
}
