.hamburger-wrapper {
  display: none;
}

.hamburger-wrapper {
  display: block;
}
.ham-dark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: lightslategray;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s ease-in-out;
  z-index: 100;
}
.ham-toggle {
  right: -300px;
}

.icon {
  margin: 1rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.close-icon {
  color: #fff;
  font-size: 30px;
  display: block;
  margin-left: auto;
}
.user-lines {
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  z-index: 99;
}

.user-wrapper {
  position: relative;
}
.user-icon-name {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  cursor: pointer;
}
.user-icon {
  font-size: 30px;
  margin: 0 7px;
}
.logput-btn-none {
  opacity: 0;
}
.logout-btn {
  position: absolute;
  top: 2.5rem;
  left: 1rem;
  width: 100px;
  padding: 0.7rem 1rem;
  color: #fff;
  background-color: #ccc;
  z-index: 3;
}
.logout-btn .logout-text {
  position: relative;
  display: inline-block;
}
.logout-btn .logout-text::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform 0.3s;
}
.logout-btn .logout-text:hover::after {
  transform: scale(1, 1);
}
.logout-clear-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(204, 204, 204, 0.01);
  z-index: 2;
}

.logout-btn-arrow {
  position: absolute;
  top: 1.9rem;
  left: 2rem;
  width: 1.7rem;
  height: 1rem;
  background: linear-gradient(to top left, #ccc 50%, transparent 50%) top left/
      50% 100% no-repeat,
    linear-gradient(to top right, #ccc 50%, transparent 50%) top right / 50%
      100% no-repeat;
}

.lines {
  display: none;
}
.line {
  display: block;
  margin: 6px;
  width: 25px;
  height: 3px;
  border-radius: 5px;
  background-color: #555;
}

.ham-username {
  color: #fff;
  margin: 3rem 0;
}
.hamburger .cats {
  color: #fff;
}
.hamburger .cats h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  padding: 7px 0;
}
.hamburger .cat {
  color: #fff;
  background-color: transparent;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.ham-user {
  margin: 2rem 0;
}

.ham-logout-btn {
  background-color: rgba(233, 150, 122, 0.6);
}
.ham-auth-link {
  display: block;
  margin: 0.5rem;
  padding: 7px;
}
.ham-auth-link:hover {
  background-color: rgba(233, 150, 122, 0.6);
}

/* @media (max-width: 890px) { */
  .lines {
    display: block;
    cursor: pointer;
  }
  .line {
    display: block;
    margin: 6px;
    width: 25px;
    height: 3px;
    border-radius: 5px;
    background-color: #555;
  }
/* } */
